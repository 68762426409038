<template>
  <div id="app">
    <div>
      <Banner />
      <Payment logos="logos1"/>
      <GameCards />
      <Payment logos="logos2"/>
      <Footer />
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Payment from "@/components/Payment.vue";
import GameCards from "@/components/GameCards/GameCards.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: { Banner, Payment, GameCards, Footer }
};
</script>
