<template>
  <a :href="url + $root.linkPayload" :target="target ? '_blank' : ''">
    <button class="Button">{{ title }}</button>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    target: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 317px;
  height: 76px;
  margin: 0 auto;
  background: var(--color-cta-default);
  box-shadow: var(--color-cta-shadow-default);
  border-radius: 6px;
  text-transform: uppercase;
  font-family: "Roboto Slab";
  font-weight: 700;
  font-size: 24px;
  line-height: 1em;
  letter-spacing: 0.01em;
  color: var(--color-black);

  &:hover {
    background: var(--color-cta-hover);
  }

  &:active {
    background: var(--color-cta-pressed);
  }

  &:disabled {
    background: var(--color-cta-disabled);
    color: var(--color-grey-light);
  }

  @media (max-width: $size-xl) {
    width: 213px;
    height: 58px;
    font-size: 16px;
  }

  @media (max-width: $size-lg) {
    width: 195px;
    height: 48px;
    font-size: 14px;
  }

  @media (max-width: $size-md) {
    width: 193px;
    height: 56px;
    font-size: 16px;
  }
}
</style>
