<template>
  <div class="GameCard-Item">
    <picture>
      <source
        type="image/webp"
        :srcset="
          require(`@/assets/images/gameCards/webp/${card.src}.webp`) +
          ' 1x, ' +
          require(`@/assets/images/gameCards/webp/${card.src}@2x.webp`) +
          '2x'
        "
        alt="card-image"
        class="GameCard-Image"
      />
      <img
        :src="require(`@/assets/images/gameCards/png/${card.src}.png`)"
        :srcset="
          require(`@/assets/images/gameCards/png/${card.src}.png`) +
          ' 1x, ' +
          require(`@/assets/images/gameCards/png/${card.src}@2x.png`) +
          '2x'
        "
        alt="card-image"
        class="GameCard-Image"
      />
    </picture>
    <h3 class="GameCard-Title">{{ card.title }}</h3>
    <div class="GameCard-Desc">{{ card.desc }}</div>
    <div class="GameCard-Button">
      <Button
        title="JUNTE-SE HOJE"
        :target="true"
        url="https://heyspin.com/?NeoDL=Registration"
      />
    </div>
  </div>
</template>

<script>
import Button from "../layout/Button.vue";
export default {
  name: "GameCard",
  components: { Button },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.GameCard {
  &-Item {
    padding: 52px 35px 70px;
    width: 390px;
    height: 530px;
    border-radius: 6px;
    text-align: center;
    z-index: 0;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 6px;
    }

    &:before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: var(--color-black);
    }

    &:after {
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
      z-index: -2;
      background: var(--color-outlines-inner-border);
    }

    @media (max-width: $size-xl) {
      width: 288px;
      height: 394px;
    }

    @media (max-width: $size-lg) {
      width: 244px;
      height: 344px;
    }

    @media (max-width: $size-md) {
      width: 288px;
      height: 394px;
      margin-bottom: 46px;
    }
  }

  &-Image {
    margin-bottom: 42px;
    height: 252px;
    width: 266px;

    @media (max-width: $size-xl) {
      width: 184px;
      height: 166px;
      margin-bottom: 32px;
    }

    @media (max-width: $size-lg) {
      width: 140px;
      height: 135px;
      margin-bottom: 20px;
    }

    @media (max-width: $size-md) {
      width: 184px;
      height: 166px;
      margin-bottom: 32px;
    }
  }

  &-Title {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: var(--color-white);
    margin-bottom: 14px;

    @media (max-width: $size-xl) {
      font-size: 18px;
    }
  }

  &-Desc {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: var(--color-grey-light);

    @media (max-width: $size-xl) {
      font-size: 12px;
    }
  }

  &-Button {
    position: absolute;
    bottom: -44px;

    @media (max-width: $size-xl) {
      bottom: -29px;
    }

    @media (max-width: $size-lg) {
      bottom: -24px;
    }

    @media (max-width: $size-md) {
      bottom: -28px;
    }
  }
}
</style>
