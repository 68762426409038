<template>
  <div class="Container">
    <div class="Banner">
      <div class="Banner-Content">
        <div class="Banner-LogoWrapper">
          <img src="@/assets/images/logo.svg" class="Banner-Logo" />
        </div>
        <div class="Banner-TitleAbove">OFERTA DE BEM-VINDO</div>
        <h1>
          <div class="Banner-Title">100% ATÉ</div>
          <div class="Banner-Title Banner-Title--third">
            R$ 1200 + 100 GIROS
          </div>
        </h1>
        <Button
          title="JOGUE AQUI"
          :target="true"
          url="https://heyspin.com/?NeoDL=Registration"
        />
        <div class="Banner-Desc">
          18+. APENAS NOVOS JOGADORES. O BÔNUS MÁXIMO PERMITIDO É R$1200. 3
          DEPÓSITOS SÃO NECESSÁRIOS PARA OS 100 GIROS. REQUISITOS MÍNIMOS DE
          APOSTAS NOS GIROS E BÔNUS: 35X,<br />
          OS GIROS EXPIRAM APÓS 24 horas.
        </div>
      </div>
      <div class="Banner__icons">
        <div>
          <img src="@/assets/images/banner/be-gamble-aware.svg" alt="BeGambleAware.org">
          <img src="@/assets/images/banner/18+.svg" alt="18+">
        </div>
        <p>Gambling can be addictive, please play responsibly.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./layout/Button.vue";
export default {
  name: "Banner",
  components: { Button }
};
</script>

<style lang="scss">
.Banner {
  height: 700px;
  background: url("@/assets/images/banner/jpg/bg.jpg");
  position: relative;

  @media (min-resolution: 2dppx) {
    background-image: url("@/assets/images/banner/jpg/bg@2x.jpg");
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @media (max-width: $size-xl) {
    height: 467px;
  }

  @media (max-width: $size-lg) {
    height: 374px;
  }

  @media (max-width: $size-md) {
    height: 548px;
    max-width: 320px;
    background: url("@/assets/images/banner/jpg/bg-mobile.jpg");
    @media (min-resolution: 2dppx) {
      background-image: url("@/assets/images/banner/jpg/bg-mobile@2x.jpg");
    }
    background-position: bottom;
    background-size: contain;
  }

  &-Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 89px 0 130px;
    text-align: center;
    height: 100%;
    position: absolute;
    right: 132px;

    @media (max-width: $size-xl) {
      right: 53px;
      padding: 50px 0 60px;
    }

    @media (max-width: $size-lg) {
      right: 68px;
      padding: 38px 0 62px;
    }

    @media (max-width: $size-md) {
      right: 27px;
      padding: 270px 0 16px;
    }
  }

  &-LogoWrapper {
    @media (max-width: $size-md) {
      position: absolute;
      top: 8px;
      border-bottom: 1px solid var(--color-grey-dark);
      width: 100%;
      padding-bottom: 8px;
    }
  }

  &-Logo {
    width: 179px;
    
    @media (max-width: $size-md) {
      width: 96px;
    }
  }

  &-TitleAbove {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--color-white);

    @media (max-width: $size-xl) {
      font-size: 20px;
    }

    @media (max-width: $size-lg) {
      font-size: 16px;
    }
  }

  &-Title {
    font-size: 50px;
    font-weight: 700;
    line-height: 125%;
    font-family: "Roboto Slab";
    font-weight: 900;
    text-transform: uppercase;
    color: var(--color-yellow);

    @media (max-width: $size-xl) {
      font-size: 44px;
    }

    @media (max-width: $size-lg) {
      font-size: 33px;
    }
  }

  &-Title--third {
    font-size: 48px;

    @media (max-width: $size-xl) {
      font-size: 36px;
    }

    @media (max-width: $size-lg) {
      font-size: 26px;
    }
  }

  &-Desc {
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 140%;
    color: var(--color-grey-light);
    max-width: 426px;
    text-transform: uppercase;

    @media (max-width: $size-xl) {
      font-size: 8px;
      max-width: 226px;
    }

    @media (max-width: $size-lg) {
      font-size: 8px;
      max-width: 195px;
    }

    @media (max-width: $size-md) {
      font-size: 10px;
      max-width: 256px;
    }
  }

  &__icons {
    position: absolute;
    top: 46px;
    right: 100%;
    width: 100%;
    transform: translate(105%, 0);

    @media (min-width: $size-lg) {
      top: 87%;
      right: 7%;
      width: auto;
      transform: translate(0,0);
    }

    @media (min-width: $size-xl) {
      top: 89%;
    }

    > div {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;

      > img {
        width: 23px;

        @media (min-width: $size-lg) {
          width: auto;
        }

        &:not(:last-child) {
          width: 164px;
          margin-right: 5px;

          @media (min-width: $size-lg) {
            width: auto;
            margin-right: 8px;
          }
        }
      }
    }

    > p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 7px;
      line-height: 1.4;
      color: var(--color-white);
      text-align: left;

      @media (min-width: $size-lg) {
        font-size: 12px;
      }
    }
  }
}
</style>
