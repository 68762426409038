<template>
  <div class="Container">
    <div class="Footer">
      Depósito mín.: <span class="Footer-Paragraph--white">R$60.</span><br />
      O bônus máximo depende do depósito (veja abaixo).<br />
      A oferta é válida por 2 semanas.<br />
      18+. Apenas novos jogadores depositantes<br />
      O pacote de boas-vindas inclui 3 bônus de depósito:<br />
      <div class="Footer-Paragraph">1 depósito: <span class="Footer-Paragraph--white">bônus de 100%, até R$1200, + 20 giros no Book of Dead</span><br /></div>
      <div class="Footer-Paragraph">2 depósito: <span class="Footer-Paragraph--white">40 giros no Book of Dead</span><br /></div>
      <div class="Footer-Paragraph">3 depósito: <span class="Footer-Paragraph--white">40 giros no Book of Dead</span><br /></div>
      Giros não usados expiram 24hrs após a sua emissão. Os giros estão
      disponíveis em Book of Dead. Os ganhos conquistados com giros que exigem
      depósitos possuem requisitos de aposta de 35x. Bônus que exigem depósito
      possuem requisitos de aposta de 35x. Os termos e condições gerais de bônus
      se aplicam aos termos que não são especificados aqui. Os ganhos recebidos
      com o uso dos bônus (sem depósito) ou giros não devem ultrapassar R$600.
      Quaisquer ganhos obtidos com bônus ou giros que excederem o máximo de
      R$600 serão considerados nulos e não serão registrados como ganhos. Apenas
      o valor máximo de R$600 pode ser sacado pelo Jogador, de acordo com os
      termos especificados na Política de Bônus A Heyspin pode cancelar, alterar
      ou modificar qualquer promoção, competição, bônus ou oferta especial, ao
      publicar os termos ou notificação de cancelamento modificados na página da
      internet relevante referente à promoção, competição ou oferta especial.
      Essa emenda, modificação ou cancelamento deve entrar em vigor após a
      expiração de um prazo de 14 dias de tal publicação, ou com efeito imedia.
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss">
.Footer {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: var(--color-grey-light);
  padding: 80px 60px;

  @media (max-width: $size-xl) {
    padding: 60px 40px;
  }

  @media (max-width: $size-lg) {
    padding: 40px 20px;
  }

  @media (max-width: $size-md) {
    padding: 20px;
    font-size: 12px;
  }

  &-Paragraph {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &-Paragraph--white {
    color: var(--color-white);
  }
}
</style>
