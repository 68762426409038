<template>
  <div class="Payment">
    <div class="Container">
      <div class="Payment-List">
        <img
          class="Payment-Image"
          v-for="(path, index) in paths"
          :key="`Payment-${index}`"
          :src="require(`@/assets/images/payment/${path}.svg`)"
          alt="logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",
  props: {
    logos: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      logos1: [
        "pix",
        "boleto",
        "payfun",
        "visa",
        "bancaria",
        "astropay",
        "ecopays",
        "muchbetter",
        "skrill",
        "neteller",
        "jeton"
      ],
      logos2: [
        "pragmatic",
        "evolution",
        "microgaming",
        "blueprint",
        "redtiger",
        "wizard",
        "stakelogic",
        "betsoft",
        "playandgo",
        "softbet",
        "netent"
      ]
    };
  },
  computed: {
    paths() {
      return this.logos === "logos1" ? this.logos1 : this.logos2;
    }
  }
};
</script>

<style lang="scss">
.Payment {
  border: solid var(--color-grey-dark);
  border-width: 1px 0 1px 0;

  &-List {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 39px 0;

    @media (max-width: $size-md) {
      padding: 16px 0;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &-Image {
    width: 126px;

    @media (max-width: $size-xl) {
      width: 76px;
    }

    @media (max-width: $size-lg) {
      width: 67px;
    }

    @media (max-width: $size-md) {
      width: 96px;
    }
  }
}
</style>
