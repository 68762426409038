<template>
  <div class="Container">
    <div class="GameCards">
      <div class="GameCards-List">
        <GameCardsItem v-for="card in cards" :card="card" :key="card.key" />
      </div>
    </div>
  </div>
</template>

<script>
import GameCardsItem from "./GameCardsItem.vue";

export default {
  name: "GameCards",
  components: { GameCardsItem },
  data() {
    return {
      cards: [
        {
          id: 1,
          title: "Caça-níqueis",
          desc: "Centenas de jogos de slot populares, incluindo Book of Dead, Gate of Olympus, Big Bass Bonanza e muito mais.",
          src: "first-card"
        },
        {
          id: 2,
          title: "Dealers ao vivo",
          desc: "Sinta a emoção da ação ao vivo e aproveite os jogos do Cassino ao vivo no seu PC e dispositivos móveis.",
          src: "second-card"
        },
        {
          id: 3,
          title: "Jogos de mesa",
          desc: "Fique no controle com a seleção Heyspin de jogos de mesa, incluindo jogos de cartas e roleta.",
          src: "third-card"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.GameCards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 100px 0;

  @media (max-width: $size-md) {
    padding: 0 0 20px 0;
  }

  &-List {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $size-md) {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
